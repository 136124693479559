
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
} from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";
import ChaKanTouSu from "./TouSu.vue";
//import RegisterTime from "./RegisterTime.vue";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "ConfigTouSu",
  props: ["dialogFormVisible"],
  emits: ["close"],
  components: { ChaKanTouSu },
  setup(props, { emit }) {
    const datePickerVisible = ref(false);
    let data = reactive<any>({
      init: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      url: "",
      maps: new Map(),
      data: {},
      option: {},
      title: "",
      total: 0, // 总条目数
      test:"1231412412",
      complaintFormList:[],
      ComplaintFormLogsList:[],
      iOperate: 0,
      selectform: {
        Phone: "",
        Status: null,
        SpName:"",        
        SpValue: 0,
        PageIndex: 1,
        PageSize: 10,
      },
      viewLogsForm:{
        ComplaintFormId: ""
      },
      tousuDialogVisible: false,
      DateSelect: [],
      
      options: [
        { label: '适老服务', value: '19' },
        { label: '健康助手', value: '4' },
        { label: '车主权益', value: '15' },
      ],
      
      shortcuts: [
    {
        text: '今天',
        onClick:(picker: any)  =>  {
            // console.log("picker");
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
        }
    },
    {
        text: '昨天',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // picker.$emit('pick', [start, end]);
        }
    },
    {
        text: '近7天',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // picker.$emit('pick', [start, end]);
        }
    },
    {
        text: '近15天',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 15)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // picker.$emit('pick', [start, end]);
        }
    },
    {
        text: '近30天',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // console.log(data.DateSelect);
            // picker.$emit('pick', [start, end]);
        }
    },
    {
        text: '近3月',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // picker.$emit('pick', [start, end]);
        }
    },
]
    });

    const changePage = ({ pageSize, currentPage }: any) => {
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getComplaintForm();
    };

    const selectTouSu = (item: any) => {      
    // let add = {Phone: "13399075993", PackagesId: 20, ContactPhone: "13399075993", Memo: ""}
    //     Api.Config.addTouSu(add).then((res: any) => {
        
    //     console.log(res);
    //     if (res.Code == 0) {  
    //         Api.Msg.success("投诉单已成功添加");
    //         getComplaintForm();
    //     } 
    //   });
      getComplaintForm();
    };

    const handleSizeChange = (val: any) => {
      data.selectform.PageSize = val;
      getComplaintForm();  
    };
    const handleCurrentChange = (val: any) => {
      data.selectform.PageIndex = val;
      getComplaintForm();  
    };
    const getComplaintForm = () => {
      //console.log(data.query);
      let booleanValue = null;
      // if(data.selectform.SpName == ""){
      //   ElMessageBox.alert("请选择业务名称")
      //   return;
      // }
      if(data.DateSelect[0] == ""){
        data.DateSelect[0] = '';
        data.DateSelect[1] = '';
      }
      if(data.DateSelect == null){
        data.DateSelect[0] = '';
        data.DateSelect[1] = '';
      }
      if(data.DateSelect[0] === undefined){
        data.DateSelect[0] = '';
        data.DateSelect[1] = '';
      }
      if(data.selectform.Status == ""){
        booleanValue = null;
      }
      else{
        booleanValue = JSON.parse(data.selectform.Status);
      }
      data.complaintFormList = [];
      let tousudata = {phone: data.selectform.Phone, Status: booleanValue, StartDate: data.DateSelect[0], EndDate: data.DateSelect[1],SpId: data.selectform.SpValue,PageIndex: data.selectform.PageIndex, PageSize: data.selectform.PageSize}
      console.log(tousudata);
      Api.Config.GetComplaintFormList(tousudata).then((res: any) => {
        // console.log("res");
        // console.log(res);
        
        if (res.Code == 0) {  
            data.total = res.Data.total;
            for(var i = 0; i < res.Data.data.length; i++){
                res.Data.data[i].StartDate = res.Data.data[i].StartDate.replace(/T/g, ' ').replace(/.[\d]{3}Z/, ' ');
                res.Data.data[i].EndDate = res.Data.data[i].EndDate.replace(/T/g, ' ').replace(/.[\d]{3}Z/, ' ');
                res.Data.data[i].CreatedAt = res.Data.data[i].CreatedAt.replace(/T/g, ' ').replace(/.[\d]{3}Z/, ' ');
                res.Data.data[i].PackagesFee = res.Data.data[i].PackagesFee / 100;
                res.Data.data[i].TotalFee = res.Data.data[i].TotalFee / 100;
                if(res.Data.data[i].Status == true){
                    res.Data.data[i].Status = "完成"
                }
                else{
                    res.Data.data[i].Status = "处理中"
                }

                if(res.Data.data[i].Results == 0){
                    res.Data.data[i].Results = "未处理"
                }
                else if(res.Data.data[i].Results == 1){
                    res.Data.data[i].Results = "退订"
                }
                else{
                    res.Data.data[i].Results = "退费"
                }

                if(res.Data.data[i].RefundType == 0){
                    res.Data.data[i].RefundType = "充值退费"
                }
                else{
                    res.Data.data[i].RefundType = "当月退费"
                }
                data.complaintFormList.push(res.Data.data[i]);
                // list.push(res.Data.data[i])
            }
            
            // console.log("2");
            
        } 
      });
            console.log(data.complaintFormList);
    };
    
    const viewLogs = (row: any) => {
      data.dialogFormVisible2 = true;
      //console.log(data.query);
      data.ComplaintFormLogsList = [];
      let logsData = {ComplaintFormId: parseInt(row.ComplaintFormId)}
      Api.Config.GetComplaintFormLogsList(logsData).then((res: any) => {
        // console.log("res");
        // console.log(res);
        
        if (res.Code == 0) {  
            for(var i = 0; i < res.Data.length; i++){
                res.Data[i].CreatedAt = res.Data[i].CreatedAt.replace(/T/g, ' ').replace(/.[\d]{3}Z/, ' ');
                switch (res.Data[i].Operate) {
                    case 0:
                        res.Data[i].Operate = "新增投诉单";
                        data.iOperate = 0
                    break;
                    case 1:
                        res.Data[i].Operate = "修改信息";
                        data.iOperate = 1
                    break;
                    case 2:
                        res.Data[i].Operate = "退订业务";
                        data.iOperate = 2
                    break;
                    case 3:
                        res.Data[i].Operate = "回访";
                        data.iOperate = 3
                    break;
                    case 4:
                        res.Data[i].Operate = "充值退费（" + "退费金额：" + (res.Data[i].Information / 100) + "元）";
                        data.iOperate = 4
                    break;
                    case 5:
                        res.Data[i].Operate = "当月退费";
                        data.iOperate = 5
                    break;
                    case 6:
                        res.Data[i].Operate = "上传退费凭证";
                        data.iOperate = 6;
                    break;
                    case 7:
                        res.Data[i].Operate = "完成投诉单";
                        data.iOperate = 7
                    break;
                    case 8:
                        res.Data[i].Operate = "直接退费";
                        data.iOperate = 8
                    break;
                    }
                data.ComplaintFormLogsList.push(res.Data[i])
            }
            // console.log("data.ComplaintFormLogsList");
            // console.log(data.ComplaintFormLogsList);
        } 
      });
    };

    const viewRefundVoucher = (row: any) => {
      let url = "https://a.xjjkzs.com/" + row.Information;
      // console.log("row");
      // console.log(row);
      data.url = url;
      data.dialogFormVisible3 = true;
    }

    const selectSpName = (item: any) => {
      // console.log("label");
      // console.log(item);
        data.selectform.SpValue = parseInt(item.value);
        data.selectform.SpName = item.label;
        // console.log(data.SpName);
        
    };

    const ChaKanTouSuRef = ref<InstanceType<typeof ChaKanTouSu>>();
    const SetTouSu = (row: any) => {
      data.tousuDialogVisible = true;
      let tousuRow = {phone: row.Phone, packagesId: row.PackagesId}
      // console.log("tousuRow");
      // console.log(row);
      ChaKanTouSuRef.value?.searchChange(tousuRow);
    };
    onMounted(() => {
        getComplaintForm();
    });

    return {
      ...toRefs(data),
      getComplaintForm,
      changePage,
      viewLogs,
      selectTouSu,
      handleSizeChange,
      handleCurrentChange,
      viewRefundVoucher,
      SetTouSu,
      ChaKanTouSuRef,
      selectSpName,
    };
  },
});
