import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "demo-image__lazy" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_ChaKanTouSu = _resolveComponent("ChaKanTouSu")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form, {
      inline: true,
      model: _ctx.selectform,
      class: "demo-form-inline"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, { label: "用户号码" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.selectform.Phone,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectform.Phone) = $event)),
              placeholder: "请输入用户号码"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: "状态" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.selectform.Status,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectform.Status) = $event)),
                  placeholder: "请选择投诉单状态",
                  clearable: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: "完成",
                      value: "true"
                    }),
                    _createVNode(_component_el_option, {
                      label: "处理中",
                      value: "false"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "选择日期" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.DateSelect,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.DateSelect) = $event)),
                  ref: "myRef",
                  onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.datePickerVisible = true)),
                  type: "daterange",
                  align: "right",
                  "value-format": "YYYY-MM-DD",
                  "unlink-panels": "",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  shortcuts: _ctx.shortcuts
                }, null, 8, ["modelValue", "shortcuts"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "业务名称" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.selectform.SpName,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectform.SpName) = $event)),
                  placeholder: "请选择业务名称",
                  onChange: _ctx.selectSpName,
                  clearable: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "onChange"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.selectTouSu,
              icon: "el-icon-search"
            }, {
              default: _withCtx(() => [
                _createTextVNode("查询")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_el_table, {
      ref: "table",
      data: _ctx.complaintFormList,
      border: "",
      style: {"width":"100%"},
      height: "70vh",
      "row-key": "complaintFormId",
      stripe: "",
      "header-cell-style": {'text-align':'center'},
      "cell-style": {'text-align':'center'}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "Phone",
          label: "用户号码"
        }),
        _createVNode(_component_el_table_column, {
          prop: "PackagesName",
          label: "套餐名称"
        }),
        _createVNode(_component_el_table_column, {
          prop: "PackagesFee",
          label: "套餐费用（元）"
        }),
        _createVNode(_component_el_table_column, {
          prop: "StartDate",
          label: "订购时间"
        }),
        _createVNode(_component_el_table_column, {
          prop: "EndDate",
          label: "退订时间"
        }),
        _createVNode(_component_el_table_column, {
          prop: "RefundType",
          label: "退费类型"
        }),
        _createVNode(_component_el_table_column, {
          prop: "TotalFee",
          label: "退费金额（元）"
        }),
        _createVNode(_component_el_table_column, { label: "操作" }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_button, {
              size: "small",
              type: "primary",
              link: "",
              onClick: ($event: any) => (_ctx.viewLogs(scope.row))
            }, {
              default: _withCtx(() => [
                _createTextVNode("查看日志")
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_el_button, {
              size: "small",
              type: "primary",
              link: "",
              onClick: ($event: any) => (_ctx.SetTouSu(scope.row))
            }, {
              default: _withCtx(() => [
                _createTextVNode("投诉处理")
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"]),
    _createVNode(_component_el_pagination, {
      onSizeChange: _ctx.handleSizeChange,
      onCurrentChange: _ctx.handleCurrentChange,
      "current-page": _ctx.selectform.PageIndex,
      "page-sizes": [10, 20, 30, 40],
      "page-size": _ctx.selectform.pageSize,
      layout: "total, sizes, prev, pager, next, jumper",
      total: _ctx.total
    }, null, 8, ["onSizeChange", "onCurrentChange", "current-page", "page-size", "total"]),
    _createVNode(_component_el_dialog, {
      title: "查看日志",
      modelValue: _ctx.dialogFormVisible2,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dialogFormVisible2) = $event)),
      width: "99%"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table, {
          ref: "table",
          data: _ctx.ComplaintFormLogsList,
          border: "",
          style: {"width":"100%"},
          height: "40vh",
          "row-key": "complaintFormId",
          stripe: "",
          "header-cell-style": {'text-align':'center'},
          "cell-style": {'text-align':'center'}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              prop: "Operator",
              label: "操作人"
            }),
            _createVNode(_component_el_table_column, {
              prop: "CreatedAt",
              label: "操作时间"
            }),
            _createVNode(_component_el_table_column, {
              prop: "Operate",
              label: "所做操作"
            }, {
              default: _withCtx((scope) => [
                _createTextVNode(_toDisplayString(scope.row.Operate) + " ", 1),
                (scope.row.Operate == '上传退费凭证')
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      size: "small",
                      type: "primary",
                      link: "",
                      onClick: ($event: any) => (_ctx.viewRefundVoucher(scope.row))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("查看退费凭证")
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              prop: "Memo",
              label: "备注"
            })
          ]),
          _: 1
        }, 8, ["data"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      title: "查看退费凭证",
      modelValue: _ctx.dialogFormVisible3,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dialogFormVisible3) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_image, { src: _ctx.url }, null, 8, ["src"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_ChaKanTouSu, {
      dialogFormVisible: _ctx.tousuDialogVisible,
      onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.tousuDialogVisible=false)),
      ref: "ChaKanTouSuRef"
    }, null, 8, ["dialogFormVisible"])
  ]))
}